import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { BulkEdit } = Dictionary

const BulkEditService = {
  download: async (payload:{}) => {
    return axios.post(BASE_URL + BulkEdit.downloadExcel(),payload )
  },
  upLoad: async (payload: {}) => {
    return axios.post(BASE_URL + BulkEdit.upload(), payload)
  },


  // Bulk Approve
  approveUpload: async (payload: {}) => {
    return axios.post(BASE_URL + BulkEdit.bulkApprove(), payload)
  },

}
export default BulkEditService

