import React from 'react'
import atoms from "../../../../components/atoms";
const { Input, Button } = atoms
const Index = ({ data, handleFileUpload, downloadExcel }: any) => {

    return (
        <div>

            <div className="px-2 d-flex gap-3 align-items-center">

                <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>{"Upload XLSX File"}</label>
                <Input
                    // className="form-control px-4 py-3"
                    type="file"
                    id="formFile"
                    accept='.xlsx, .xls, .csv'
                    multiple={true}
                    onChange={handleFileUpload}
                    placeholder={"formate :- .xlsx, .xls"}
                    onClick={(e: any) => (e.target.value = null)}
                />
                <div className='mx-3 my-auto'>
                    <Button name={"Bulk Approve"}
                        className='add-btn'
                        onClick={() => downloadExcel(data)}
                        isDisabled={data?.length === 0}
                    />
                </div>
            </div>

        </div>
    )
}

export default Index