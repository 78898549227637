import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { BulkFilter } = Dictionary

const BulkFilterService = {
  download: async (payload:{}) => {
    return axios.post(BASE_URL + BulkFilter.bulkFilterDownload(),payload )
  },
}
export default BulkFilterService

