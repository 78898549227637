import atoms from "../../../atoms";
import { IndexProps } from "./interface";

const { Input, Select, Button } = atoms;

const Index = ({
  handleChange,
  reportData,
  storeData,
  submitIndividualInvoices,
}: IndexProps) => {
  const buttonValidation = (date1: Date, date2: Date) => {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (!parsedDate1 || isNaN(parsedDate1.getTime())) {
      return true;
    }
    if (!parsedDate2 || isNaN(parsedDate2.getTime())) {
      return true;
    }

    if (!reportData?.store_ids.value) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="row">
        <div className="col-4" style={{ color: "#000000" }}>
          <Select
            className={"w-100"}
            options={storeData}
            value={reportData.store_ids}
            onChange={(e: any) => handleChange("store_ids", e)}
            placeholder={"Select"}
            label={"Select Stores"}
          />
        </div>
        <div className="col-4">
          <Input
            label={"Start Date"}
            placeholder={"Start Date"}
            type="date"
            value={reportData?.created_from}
            onChange={(e: any) => {
              handleChange("created_from", e);
            }}
          />
        </div>
        <div className="col-4">
          <Input
            label={"End Date"}
            placeholder={"Enter Invoice Date"}
            type="date"
            value={reportData?.created_to}
            onChange={(e: any) => {
              handleChange("created_to", e);
            }}
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "10rem" }}
      >
        <Button
          isDisabled={buttonValidation(
            reportData?.created_from,
            reportData?.created_to
          )}
          style={{ minWidth: "259px" }}
          onClick={() => submitIndividualInvoices()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default Index;
