import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import atoms from '../../../atoms'
import { RxCross2 } from 'react-icons/rx'
const { Input, Button, LocationBadge } = atoms
const AddNew = (props: any) => {
    const [groupName, setGroupName] = useState<string>('')
    const [data, setData] = useState<any[]>(props.remainingList);
    const [filteredData, setFilteredData] = useState<any[]>(props.remainingList);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedValues, setSelectedValues] = useState<any>([]);

    // Handle search input change
    const handleSearch = (e: any) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);

        const filtered = data.filter((item) =>
            item.toLowerCase().includes(searchValue.toLowerCase())
        );

        setFilteredData(filtered.length ? filtered : [searchValue]);
    };

    // Handle selection of an item
    const handleSelect = (value: any) => {
        if (selectedValues && !selectedValues.includes(value)) {
            setSelectedValues((prev: any) => [...prev, value]);
            setData(data.filter((item) => item !== value));
            setSearchTerm(""); // Clear the search term after selection
            setFilteredData(data.filter((item) => item !== value)); // Update dropdown options
        }
    };

    // Handle deselection of an item
    const handleDeselect = (value: any) => {
        // Remove the value from selectedValues
        setSelectedValues(selectedValues.filter((item: any) => item !== value));
        // Add it back to the data array and re-apply the search filter
        const updatedData = [...data, value];
        setData(updatedData);

        // Re-filter the data to ensure the dropdown reflects the current search term
        const filtered = updatedData.filter((item) =>
            item.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
    };

    // Handle Enter key press
    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && searchTerm.trim()) {
            // If search term is not already selected, add it
            if (!selectedValues.includes(searchTerm)) {
                handleSelect(searchTerm);
            }
        }
    };

    const handleSubmit = () => {
        let payload = {
            group_name: groupName,
            pincodes: selectedValues
        }
        props?.setPayload(payload);
    }

    useEffect(() => {
        if (props.isEdit) {
            setGroupName(props.editData.group_name || "");
            setSelectedValues(props.editData.pincodes || []);
        } else {
            setGroupName("");
            setSelectedValues([]);
        }
    }, [props.editData])

    return (
        <Modal className="h-100 d-flex justify-content-center align-items-center modal-m"
            isOpen={props.show}
        >
            <ModalHeader className="d-flex border-0 position-relative"  >
                Add New Group
                <div className="position-absolute py-1 px-2"
                    style={{
                        top: '14%', right: '3%', backgroundColor: 'transparent', border: '1px solid #222',
                        borderRadius: '50%', cursor: 'pointer'
                    }}
                    onClick={props.onHide}>
                    <RxCross2 style={{ color: '#222' }} />
                </div>
            </ModalHeader>
            <ModalBody className="rounded-10">
                <div className="selected-values mt-3">
                    <h5 className='inputs-label mb-2 fs-16 fw-600  secondary-color'>Remaining Pincodes</h5>
                    <div className="group-items">
                        {data && data.map((item: any, index: any) => (
                            <LocationBadge
                                key={index}
                                label={item}
                            // onClick={() => handleDeselect(item)}
                            // isRemove={true}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <Input
                        label='Group Name'
                        type="text"
                        placeholder="Enter Group Name"
                        onChange={(e: any) => setGroupName(e.target.value)}
                        value={groupName}
                    />

                </div>
                <div className="form-group">
                    <Input
                        label='Pincode'
                        type="text"
                        className="form-control"
                        value={searchTerm}
                        onChange={handleSearch}
                        onKeyDown={handleKeyDown}
                        placeholder="Search or add..."
                    />
                </div>
                {searchTerm.length > 0 && <ul className="list-group dropdown-list">
                    {filteredData.map((item, index) => (
                        <li
                            key={index}
                            className="list-group-item"
                            onClick={() => handleSelect(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>}

                <div className="selected-values mt-3">
                    <h5 className='inputs-label mb-2 fs-16 fw-600  secondary-color'>Selected Pincodes</h5>
                    <div className="group-items">
                        {selectedValues && selectedValues.map((item: any, index: any) => (
                            <LocationBadge
                                key={index}
                                label={item}
                                onClick={() => handleDeselect(item)}
                                isRemove={true}
                            />
                        ))}
                    </div>
                </div>
                <div className='text-center mt-25'>
                    <Button className='rounded-2 ms-3 px-4 '
                        onClick={() => handleSubmit()}>Submit</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddNew