import React from 'react';
import './index.scss'
import { BudgesProps } from './interface';

const Index = ({ count, style, className }: BudgesProps) => {
    return (
        <>
           {(count && count>0) ? <span className={`${!className && "translate"} ${className} bg-danger badge rounded-pill badges-style`} style={style}>
                {`${count ? count : 0}`}
                {/* <span className="visually-hidden">unread messages</span> */}
            </span> : ""}
        </>
    );
};

export default Index;
