import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import { findConstantLabel, handleReportDownload } from '../../../../constants/utils'
import constant from '../../../../constants/constant'
import service from "../../../../api/services";
import atoms from '../../../../components/atoms'
import { useToasts } from 'react-toast-notifications';
import * as XLSX from "xlsx"
import { HeaderHeading } from '../../../../Context'

const { BulkFilter } = organisms
const { Input, Button } = atoms

const Index = () => {
    const [data, setData] = useState<any>([])
    const [filteredData, setFilteredData] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);

    const { addToast } = useToasts();

    useEffect(() => {
        setHeader("Product - Bulk Filter")
    }, [])

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            try {
                const data = event.target?.result;
                if (typeof data === 'string') {
                    const workbook = XLSX.read(data, { type: 'binary' });


                    const parsedData: { [key: string]: any[] } = {};
                    workbook.SheetNames.forEach((sheetName) => {
                        const sheet = workbook.Sheets[sheetName];
                        parsedData['Product_list'] = XLSX.utils.sheet_to_json(sheet);
                    });
                    if (parsedData) {
                        setData(parsedData?.Product_list);
                    }
                  

                } else {
                    throw new Error('Invalid file data');
                }
            } catch (error) {
                console.error('Error parsing Excel file:', error);
            } finally {
                setIsLoading(false);
            }
        };

        reader.onerror = (event: ProgressEvent<FileReader>) => {
            console.error('Error reading the file:', event?.target?.error);
            setIsLoading(false);
        };

        if (e.target.files && e.target.files[0]) {
            reader.readAsBinaryString(e.target.files[0]);
        } else {
            console.error('No file selected');
            setIsLoading(false);
        }
    };



    const handleBulkSubmit = async () => {

        let payload = {
            product_details: data
        }
        try {
            let response = await service.bulkFilterService.download(payload)
         
            addToast('Product Filter successfully', {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
            handleReportDownload(response?.data?.data, "filter-product-report", 2, "SpecxWeb")

        } catch (error) {

            console.log("Error", error)
        }
    }



    return (
        <Wrapper>
            <div className='col '>
                <BulkFilter
                    data={data}
                    handleFileUpload={handleFileUpload}
                    downloadExcel={handleBulkSubmit}
                />
            </div>
            {isLoading &&
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center'
                    }}
                    className="loading-One"
                >
                    <div
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: "50%",
                            zIndex: '9999'
                        }}
                        className="spinner-border text-primary loading-Two" role="status">

                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }
        </Wrapper>
    )
}

export default Index