import React from "react";
import moment from "moment";
import atoms from "../../../atoms";
import ThreeDots from "../../../../assets/Images/threeDots.svg";
import { IndexProps } from "./interface";
import { MdOutlineFileDownload } from "react-icons/md";
import { FiShare } from "react-icons/fi";
import { numberWithCommas } from "../../../../constants/utils";

const { Search, Table, Image, Pagination } = atoms;
const Index = ({
  setSearch,
  storeListing,
  setLimitPerPage,
  setCurrentPage,
  handleDownload,
  handleShare,
  currentPage,
  limit,
  isLoading,
}: IndexProps) => {
  const columns = [
    {
      name: "Date",
      selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Note No.",
      selector: (row: any) => (row.note_number ? row.note_number : "-"),
      sortable: true,
      minWidth: "150px",
    },
    {
        name: "Store",
        selector: (row: any) => (row?.store?.store_name ? row?.store?.store_name : "-"),
        sortable: true,
        minWidth: "150px",
      },
    {
      name: "Period",
      selector: (row: any) =>
        `${moment(row?.from_date)?.format("DD/MM/YYYY")} - ${moment(
          row?.to_date
        ).format("DD/MM/YYYY")}` || "-",
      sortable: true,
    },
    {
      name: "Net Amount (₹)",
      selector: (row: any) =>
        numberWithCommas(
          parseFloat(
            (row?.payable_by_store - row?.payable_by_specxweb)?.toFixed(2)
          )
        ) || 0,
    },
    { name: "Action", selector: (row: any) => row.action },
  ];
  return (
    <div>
      <div className="row align-items-center">
        <div className="col-6">
          <Search
            searchCallBack={(e: string) => {
              setSearch(e);
            }}
          />
        </div>
      </div>
      <div>
        <Table
          AdditionalClassName="add-invertory-table"
          columns={columns}
          data={storeListing?.data?.map((item: any) => {
            return {
              ...item,
              action: (
                <>
                  {" "}
                  <div className="table-data-width">
                    <button
                      className="btn dropdown-dots shadow-none action-button"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Image src={ThreeDots} />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end dropdown-style"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {/* Download Option */}

                      <li>
                        <button
                          className="dropdown-item dropdown-item-size"
                          disabled={isLoading}
                          onClick={() => handleDownload(item?.url)}
                        >
                          <div className="d-flex align-items-center">
                            <MdOutlineFileDownload />
                            <span className="ms-2 mb-0">Download</span>
                          </div>
                        </button>
                      </li>
                      <hr className="my-1"></hr>

                      {/* Share Option */}
                      <li>
                        <button
                          className="dropdown-item dropdown-item-size"
                          disabled={isLoading}
                          onClick={() =>
                            handleShare({
                              id: item?.id,
                              store_id: item?.store?.id,
                              from_date: item.from_date,
                              to_date: item.to_date,
                            })
                          }
                        >
                          <div className="d-flex align-items-center">
                            <FiShare />
                            <span className="ms-2 mb-0">Share</span>
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              ),
            };
          })}
        />
      </div>
      <div>
        <Pagination
          totalCount={storeListing?.count}
          currentPage={currentPage}
          totalPages={storeListing?.totalPages}
          onChangePage={(page: any) => setCurrentPage(page)}
          setLimit={(e: number) => setLimitPerPage(e)}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default Index;
