import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import { useToasts } from 'react-toast-notifications';
import * as XLSX from "xlsx"
import atoms from '../../../../components/atoms';
import { errorPushFormat } from './bulkEditUtlis';
import service from '../../../../api/services';
import constant from '../../../../constants/constant';
import { findConstantLabel } from '../../../../constants/utils';
import { HeaderHeading } from '../../../../Context'

const { BulkEdit } = organisms
const { Input } = atoms

const Index = () => {
    const [data, setData] = useState<any>([])
    const [imageData, setImageData] = useState<any>()
    const [imageApiData, setImageApiData] = useState<any>([])
    // make useState for store api data for validation
    const [productTypeValid, setProductTypeValid] = useState<any>([]);
    const [brandData, setBrandData] = useState([])
    const [productMaterial1, setProductMaterial1] = useState<any>([])
    const [swColorName, setSWColorName] = useState([])
    const [errorRow, setErrorRow] = useState<any>([])
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);


    const { addToast } = useToasts();

    useEffect(() => {
        setHeader("Bulk-Edit")
        setErrorRow([])
        fetchData()
    }, [])

    const isNumber = (n: any) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
    // call api for validition of upload xlsx file column data
    const fetchData = async () => {
        setImageData([])
        try {
            const productTypeResponse = await service.productData.productTypeListingService({ search: '' });
            if (productTypeResponse.status === 200) {
                setProductTypeValid(productTypeResponse.data.data);
            }

            const brandResponse = await service.productData.brandListingService({ search: '' });
            if (brandResponse.status === 200) {
                setBrandData(brandResponse.data.data);
            }

            const productMaterialResponse = await service.productData.productMaterialListingService({ search: '' });
            if (productMaterialResponse.status === 200) {
                setProductMaterial1(productMaterialResponse.data.data);
            }

            const swColorResponse = await service.productData.productColorListingService({ search: '' });
            if (swColorResponse.status === 200) {
                setSWColorName(swColorResponse.data.data);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };

    const validateXlsxFileData = async (fileData: any) => {
        const errors: any[] = [];

        fileData.forEach((data: any, index: number) => {
            const { product_type_initials: type, brand_code: brand, brand_color_code, glass_size, isPolarised, modal_number, product_material, sw_color_name, weight, warranty, rim_type, gender } = data;

            const missingFields: string[] = [];

            if (!type) missingFields.push("type");
            if (!brand) missingFields.push("brand");
            if (!brand_color_code) missingFields.push("brand_color_code");
            if (!glass_size) missingFields.push("glass_size");
            if (!isPolarised) missingFields.push("isPolarised");
            if (!modal_number) missingFields.push("modal_number");

            if (missingFields.length > 0) {
                errors.push({
                    id: data.product_id,
                    serial_no: index + 1,
                    column: `Missing fields: ${missingFields.join(", ")}`
                });
            }

            if (type || brand || brand_color_code || glass_size || isPolarised || modal_number || product_material) {
                if (!validProductType(type)) {
                    errors.push(errorPushFormat({ data: data, message: type, error: type, column: 'Product Type Initials', serial_no: index + 1 }));

                }
                if (!validBrandCode(brand)) {
                    errors.push(errorPushFormat({ data: data, message: brand, error: brand, column: "Brand Code", serial_no: index + 1 }));
                }

                if (product_material) {
                    let productMaterialError = validateProduct(product_material, "name", productMaterial1)
                    if (productMaterialError?.length > 0) {
                        errors.push(errorPushFormat({ data: data, message: product_material, error: productMaterialError, column: "Product Material", serial_no: index + 1 }));
                    }
                }

                if (sw_color_name) {
                    const swColorNameError = validateProduct(sw_color_name, "color_name", swColorName)
                    if (swColorNameError?.length > 0) {
                        errors.push(errorPushFormat({ data: data, message: sw_color_name, error: swColorNameError, column: "sw_color_name", serial_no: index + 1 }));

                    }
                }

                if (isPolarised > 3 || !isNumber(isPolarised)) {
                    errors.push(errorPushFormat({ data: data, message: "Polarised should be 1, 2 or 3", error: isPolarised, column: "isPolarised", serial_no: index + 1 }));
                }

                if (weight && !isNumber(weight)) {
                    errors.push(errorPushFormat({ data: data, error: "should be integer", column: "Weight", serial_no: index + 1 }));
                }
                if (warranty && !isNumber(warranty)) {
                    errors.push(errorPushFormat({ data: data, error: "should be integer", column: "Warranty", serial_no: index + 1 }));
                }
                if (rim_type && !isNumber(rim_type)) {
                    errors.push(errorPushFormat({ data: data, error: "should be integer", column: "Rim_type", serial_no: index + 1 }));

                }
            }

        });

        await setErrorRow(errors);
        setData(fileData);
    };

    const validateProduct = (arrayName: any, key: string, materialData: any) => {
        const arr = (arrayName && !isNumber(arrayName)) && arrayName?.split(',')
        let err: any = []
        for (let i = 0; i < arr.length; i++) {

            const found = materialData?.find((element: any) => element?.[key]?.trim() == arr[i]?.trim());
            if (!found) {
                err.push(arr[i])
            }
        }
        return err
    }

    const validProductType = (type: string): boolean => {
        return productTypeValid?.some((element: any) => element?.product_type_initials === type);
    };

    const validBrandCode = (brand: string): boolean => {
        return brandData?.some((element: any) => element?.brand_code === brand);
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorRow([])

        try {
            const file = e?.target?.files?.[0];
            if (!file) {
                throw new Error('No file selected');
            }

            const data = await readFileAsync(file);
            const parsedData = parseExcelData(data);

            validateXlsxFileData(parsedData);
        } catch (error) {
            console.error('Error handling file upload:', error);
        }
    };

    const readFileAsync = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event: ProgressEvent<FileReader>) => {
                const result = event.target?.result;
                if (typeof result === 'string') {
                    resolve(result);
                } else {
                    reject(new Error('Invalid file data'));
                }
            };

            reader.onerror = () => {
                reject(new Error('Error reading the file'));
            };

            reader.readAsBinaryString(file);
        });
    };

    const parseExcelData = (data: string): any[] => {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        return XLSX.utils.sheet_to_json(sheet);
    };


    // extract image folder Name for image file upload
    const extractNumber = (inputString: string) => {
        const regex = /\/(\d+)\//;
        const match = inputString.match(regex);
        if (inputString) {
            if (match) {
                return match[1]
            } else {
                return inputString?.split('/')[0]
            }
        }

        return null;
    }

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = event.target.files; // Uploaded file data
        setImageApiData(files)

        if (!files || files.length === 0) {
            return; // No files to process
        }

        const promises: Promise<{ id: number; imageUrl: string }>[] = [];

        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];
            const image_folder_name: any = extractNumber(file?.webkitRelativePath);

            promises.push(
                new Promise((resolve) => {
                    const imageUrl = URL.createObjectURL(files[i]);
                    const imageData = { id: image_folder_name, imageUrl: imageUrl };
                    resolve(imageData);
                })
            );
        }

        Promise.all(promises)
            .then((imageDataArray) => {
                setImageData(imageDataArray);
            })
            .catch((error) => {
                // Handle any errors that occurred during file reading
                console.error('Error reading files:', error);
            });
    };


    const productLabel = (type: any, data: any, label: any, searchLabel: any) => {
        for (let i = 0; i < data?.length; i++) {
            if (type == data[i][searchLabel]) {
                return data[i][label].toUpperCase()
            }
        }
    }
    const getDescription = (items: any) => {
        if (items) {
            let tempProductType = items?.product_type_initials ? productLabel(items?.product_type_initials, productTypeValid, 'name', 'product_type_initials') : '';
            let tempBrandID = items?.brand_code ? productLabel(items?.brand_code, brandData, 'brand_name', 'brand_code') : '';
            let tempProductRimType = items?.rim_type ? findConstantLabel(constant.PRODUCT_RIM_TYPE, parseInt(items?.rim_type)) : '';
            let tempColorCode = items?.brand_color_name || null;
            let tempProductShape = items?.product_shape?.replace(/,/g, ' ') || "";
            let tempPolarized = items?.isPolarised === 1 ? 'POLARIZED' : '';
            let tempDescription = [tempBrandID, tempColorCode ? tempColorCode + ' ' + 'COLOR' : '', tempProductShape, tempProductRimType, tempPolarized, tempProductType,];
            tempDescription = tempDescription?.filter(value => value && value.trim() !== '')
            const description = tempDescription?.join(' ');
            return description
        }
    }

    const imageForApi = (imageFolderName: number, data: any) => {
        let files = data
        let tempImage: any = []
        for (let i = 0; i < files?.length; i++) {
            const image_folder_name = extractNumber(files[i]?.webkitRelativePath)
            if (image_folder_name) {
                let id = parseInt(image_folder_name)
                if (id == imageFolderName) {
                    tempImage.push(files[i])
                }
            }
        }
        return tempImage
    }

    const handleBulkSubmit = async () => {
        const errors: any[] = [];
        for (let i = 0; i < data?.length; i++) {
            const description = await getDescription(data[i])
            const imageFolderName = data[i]?.image_folder_name
            const image = imageForApi(imageFolderName, imageApiData)
            await handelBulkUploadApi(data[i], image, description, errors, i)
        }
        addToast('File Upload successfully', {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
        });
        setErrorRow(errors);
    }
    // Function to handle the api of bulk upload
    const handelBulkUploadApi = async (items: any, productImage: any, description: any, errors: any, index: number) => {
        const formData = new FormData();
        let singleKey: string[] = [
            'product_id', 'modal_number', 'brand_color_code', 'brand_color_name', 'glass_size',
            'temple_size', 'bridge_size', 'features', 'rim_type', 'gender', 'product_srp', 'warranty',
            'weight', 'product_with_case', 'brand_code', 'product_shape', 'sw_color_name', 'product_material',
            'isPolarised', 'sw_lens_color'
        ]

        for (const key in items) {
            if (items.hasOwnProperty(key) && items[key] !== undefined &&
                items[key] !== '' && items[key] !== null) {
                if (singleKey.includes(key)) {
                    formData.append(key, items[key])
                } else if (key === "description" || key === 'image') {

                } else {
                    formData.append(key, items[key]);
                }
            }
        }
        { productImage.length > 0 && productImage?.forEach((image: any) => formData.append('product_images', image)); }
        { description && formData.append('description', `${description}, ${items?.description ? items?.description : ""} `); }
        try {
            await service.BulkEditService.upLoad(formData)
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                // change the image folder name with product Id
                errors.push({ id: items?.product_id, error: error.response.data.message, serial_no: index });
            } else {
                errors.push({ id: items?.product_id, error: "An error occurred while processing your request" });
            }
        }
    }

    const handleRemove = (id: string) => {
        let arr = [...data]
        // 
        // const index = data.findIndex((item: any) => item?.image_folder_name === id);
        const index = data.findIndex((item: any) => item?.product_id === id);

        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.splice(0, 1);
        }
        setData(arr)
    }

    return (
        <Wrapper>
            <div className='col '>
                <div className="px-2 d-flex gap-3 align-items-center">
                    <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>{"Upload XLSX File"}</label>
                    <Input
                        type="file"
                        id="formFile"
                        accept='.xlsx, .xls, .csv'
                        multiple={true}
                        onChange={handleFileUpload}
                        placeholder={"formate :- .xlsx, .xls"}
                        onClick={(e: any) => (e.target.value = null)}
                    />
                </div>
            </div>

            <div>
                <BulkEdit
                    data={data}
                    imageData={imageData}
                    handleImageUpload={handleImageUpload}
                    handleBulkSubmit={handleBulkSubmit}
                    errorRow={errorRow}
                    handleRemove={handleRemove}
                />
            </div>
        </Wrapper>
    )
}

export default Index