import React, { useEffect, useState } from 'react'
import organisms from '../../../../components/organisms'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { Stepper, BottomNav } from '../../../../components/common/Stepper/Stepper'
import service from '../../../../api/services'
import { useToasts } from 'react-toast-notifications'
import { useNavigate, useParams } from 'react-router'
import { Store_Legal_Status } from '../../../../constants/defaultValues'
import { getDropdownValue, emailValidation } from '../../../../constants/utils'
import constant from '../../../../constants/constant'
import molecules from '../../../../components/molecules'
import { IStoreData } from './interface'
import { validationRulesStepOne, validationRulesStepThree, validationRulesStepTwo } from './validationRules'
import { City_List, State_List } from '../../../../constants/state_cities'
const { AddStoreOrganism } = organisms
const { TopBar } = molecules
const AddStore = () => {
    const [step, setStep] = useState(0)
    const [submitClicked, setSubmitClicked] = useState<boolean>(false)
    const [imagesURL, setImagesURL] = useState([])
    const [bannerImagesURL, setBannerImagesURL] = useState([])
    const params = useParams()
    const { addToast } = useToasts();
    const navigate = useNavigate()
    const [errors, setErrors] = useState<any>({});
    const [emailError, setEmailError] = useState<any>({})
    const [imageDeleteID, setImageDeleteId] = useState([])
    const [updateStoreLocation, setUpdateStoreLocation] = useState("")
    const [storeDetails, setStoreDetails] = useState<any>({
        store_id: "",
        store_code: "",
        store_name: "",
        store_contact_number: '',
        store_address: "",
        store_location: "",
        city: '',
        store_images: [],
        cover_index: 0,
        state: '',
        pin_code: '',
        owner_name: "",
        owner_mobile: '',
        owner_email: "",
        owner_is_manager: "",
        manager_name: "",
        manager_mobile: '',
        manager_email: "",
        tds: '',
        credit: '',
        latitude: "",
        longitude: "",
        bank_name: "",
        ifsc_code: "",
        account_number: '',
        legal_status: '',
        total_staffs: '',
        additional_charge: '',
        gst_number: '',
        furniture_type: '',
        optometrist_available: '',
        chair_unit_available: '',
        ar_machine_available: '',
        lensometer_available: '',
        slit_lamp: '',
        gst_type: 3,
        gst_document: "",
        pan_number: "",
        pan_document: "",
        gumasta_number: "",
        gumasta_document: "",
        nagar_nigam_number: "",
        nagar_nigam_document: "",
        incorporation_number: "",
        incorporation_document: "",
        agency_number: "",
        agency_document: "",
        admin_username: "",
        admin_name: "",
        admin_password: "",
        store_count: "",
        admin_change_password: "",
        store_admin_id: '',
        is_warehouse: false,
        opening_time: '',
        closing_time: '',
        store_banner: [],
        // stock_ownership:false
    })
    const steps = [
        'Basic Details',
        'Profile Details',
        'Documents',
    ];
    const _handleSubmitBtnClick = () => {
        const isValid = validate();
        if (isValid)
            setSubmitClicked(!submitClicked)
    };
    useEffect(() => {
        if (submitClicked) {
            _handleSubmit(storeDetails)
        }
        if (!params.id) {
            getStoreCount()
        }
    }, [submitClicked])

    const getStoreCount = async () => {
        let tempStoreDetails = storeDetails
        try {
            let response = await service.store.storeCount()
            if (response.status === 200) {
                tempStoreDetails.store_count = response.data.count
                setStoreDetails(tempStoreDetails)
            }
        } catch (error) {

        }
    }

    const _handleSubmit = async (storeData: any) => {
        const formdata: any = new FormData();
        let multiKey: any = ['legal_status', 'furniture_type', 'city', 'state']
        for (const key in storeDetails) {
            if (storeDetails.hasOwnProperty(key) && storeData[key] !== undefined &&
                storeData[key] !== '' && storeData[key] !== null) {
                if (multiKey.includes(key)) {
                    formdata.append(key, storeData[key].value)
                } else if (key === 'store_images') {
                    storeData?.store_images.forEach((image: any) => formdata.append('store_images', image));
                } else if (key === 'store_banner') {
                    storeData?.store_banner.forEach((image: string) => formdata.append('store_banner', image));
                } else {
                    formdata.append(key, storeData[key]);
                }
            }
        }

        try {
            if (params?.id) {
                const response = await service.store.updateStoreService(params?.id, formdata, { "Content-type": "multipart/form-data" })
                if (response.status === 200) {
                    navigate('/stores')
                    addToast('Store updated successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            }
            else {
                const response = await service.store.createStoreService(formdata, { "Content-type": "multipart/form-data" })
                if (response.status === 201) {
                    navigate('/stores')
                    addToast('Store added successfully', {
                        appearance: 'success',
                        autoDismiss: true,
                        autoDismissTimeout: 3000,
                    });
                }
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const getStoreDetails = async () => {
        try {
            const res = await service.store.getStoreByID(params?.id || '')
            if (res.status === 200) {
                let response: any = res?.data?.data
                setImagesURL(response?.images)
                setBannerImagesURL(response?.store_banner || [])
                setStoreDetails({
                    store_id: response.store_id_display,
                    store_code: response?.store_code,
                    store_count: response.store_id_display.slice(-3),
                    store_name: response?.store_name,
                    store_contact_number: response?.store_contact_number,
                    store_address: response?.store_address,
                    store_location: response?.store_location,
                    city: {
                        value: getDropdownValue(City_List, response?.city).value,
                        label: getDropdownValue(City_List, response?.city).label,
                    },
                    store_images: response?.images,
                    store_banner: response?.store_banner || [],
                    cover_index: response?.cover_index,
                    state: {
                        value: getDropdownValue(State_List, response?.state).value,
                        label: getDropdownValue(State_List, response?.state).label,
                    },
                    admin_username: response?.store_admin?.login_id,
                    admin_password: '******',
                    admin_name: response?.store_admin?.username,
                    store_admin_id: response?.store_admin?.id,
                    pin_code: response?.pin_code,
                    owner_name: response?.owner_name,
                    owner_mobile: response?.owner_mobile,
                    owner_email: response?.owner_email,
                    owner_is_manager: response?.owner_is_manager.toString(),
                    manager_name: response?.manager_name,
                    manager_mobile: response?.manager_mobile,
                    manager_email: response?.manager_email,
                    legal_status: {
                        value: getDropdownValue(Store_Legal_Status, response?.legal_status)?.value,
                        label: getDropdownValue(Store_Legal_Status, response?.legal_status)?.label
                    },
                    tds: response?.tds,
                    credit: response?.credit,
                    latitude: response?.latitude,
                    longitude: response?.longitude,
                    opening_time: response?.opening_time,
                    closing_time: response?.closing_time,
                    bank_name: response?.bank_details?.bank_name,
                    ifsc_code: response?.bank_details?.ifsc_code,
                    account_number: response?.bank_details?.account_number,
                    total_staffs: response?.total_staffs,
                    additional_charge: response?.additional_charge,
                    furniture_type: {
                        value: getDropdownValue(constant.STORE_FURNITURE_TYPE, response?.furniture_type).value,
                        label: getDropdownValue(constant.STORE_FURNITURE_TYPE, response?.furniture_type).label
                    },
                    optometrist_available: response?.optometrist_available?.toString(),
                    chair_unit_available: response?.chair_unit_available?.toString(),
                    ar_machine_available: response?.ar_machine_available?.toString(),
                    lensometer_available: response?.lensometer_available?.toString(),
                    slit_lamp: response?.slit_lamp?.toString(),
                    gst_type: response?.gst_type,
                    is_warehouse: response?.is_warehouse,
                    // stock_ownership:response?.stock_ownership

                })
                setUpdateStoreLocation(response?.store_location)
                printObjectsByDocType(response?.store_documents)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    const printObjectsByDocType = (apiResponse: any) => {

        setStoreDetails((prevState: any) => {
            const updatedState = { ...prevState };

            apiResponse.forEach((item: any) => {
                const { doc_type, url } = item;
                switch (doc_type) {
                    case "GST":
                        updatedState.gst_number = item.doc_number;
                        updatedState.gst_document = url;
                        break;
                    case "PAN":
                        updatedState.pan_number = item.doc_number;
                        updatedState.pan_document = url;
                        break;
                    case "GUMASTA":
                        updatedState.gumasta_number = item.doc_number;
                        updatedState.gumasta_document = url;
                        break;
                    case "NAGAR_NIGAM":
                        updatedState.nagar_nigam_number = item.doc_number;
                        updatedState.nagar_nigam_document = url;
                        break;
                    case "INCORPORATION":
                        updatedState.incorporation_number = item.doc_number;
                        updatedState.incorporation_document = url;
                        break;
                    case "AGENCY":
                        updatedState.agency_number = item.doc_number;
                        updatedState.agency_document = url;
                        break;
                    default:
                        // Handle any other doc_type if needed
                        break;
                }
            });

            return updatedState;
        });

    }
    useEffect(() => {
        if (params?.id) {
            getStoreDetails()
        }
    }, [])

    const validate = () => {
        let newErrors: any = {};

        if (step === 0) {
            for (const field in validationRulesStepOne) {
                if (validationRulesStepOne.hasOwnProperty(field)) {
                    // General validation
                    // if (!((storeDetails.owner_is_manager === 'true') && (field === 'manager_name' || field === 'manager_mobile' || field === "manager_email"))) {
                        if (validationRulesStepOne[field]?.required && !storeDetails[field]) {
                            newErrors[field] = "This field is required.";
                        } else if ((validationRulesStepOne[field]?.label?.required) && (!storeDetails[field]?.label)) {
                            newErrors[field] = "Please Select field";
                        } else if ((validationRulesStepOne[field][0]?.label?.required) && (!storeDetails[field][0]?.label)) {
                            newErrors[field] = "Please Select field";
                        } else if (storeDetails[field]?.length === 0 && imagesURL?.length === 0 && field !== "opening_time" && field !== "closing_time" && field !== 'latitude' && field !== 'longitude') {
                            newErrors[field] = "Please upload Images";
                        } else if (validationRulesStepOne[field]?.minLength < 10) {
                            newErrors[field] = `Maximum length is ${validationRulesStepOne[field].minLength}`;
                        } else if (!emailValidation(storeDetails.owner_email)) {
                            newErrors.owner_email = "Invalid Email";
                            setEmailError((prevErrors: any) => ({
                                ...prevErrors, owner_email: "Invalid Email"
                            }));
                        } else if (!emailValidation(storeDetails.manager_email)) {
                            newErrors.manager_email = "Invalid Email";
                            setEmailError((prevErrors: any) => ({
                                ...prevErrors, manager_email: "Invalid Email"
                            }));
                        } else if (validationRulesStepOne[field]?.required && field === "store_location" && errors?.store_location) {
                            newErrors.store_location = errors?.store_location;
                        }
                    // }

                    // Opening and Closing Time validation
                    if (field === "opening_time") {
                        if (storeDetails.closing_time && !storeDetails.opening_time) {
                            newErrors.opening_time = "Store Open Time is required if Store Close Time is provided.";
                        } else if (storeDetails.opening_time && storeDetails.closing_time && storeDetails.opening_time > storeDetails.closing_time) {
                            newErrors.closing_time = "Opening time should be earlier.";
                        }
                    } else if (field === "closing_time") {
                        if (storeDetails.opening_time && !storeDetails.closing_time) {
                            newErrors.closing_time = "Store Close Time is required if Store Open Time is provided.";
                        } else if (storeDetails.opening_time && storeDetails.closing_time && storeDetails.opening_time > storeDetails.closing_time) {
                            newErrors.closing_time = "Opening time should be earlier.";
                        }
                    }

                    // Latitude and Longitude validation
                    if (field === "latitude") {
                        if (storeDetails.longitude && !storeDetails.latitude) {
                            newErrors.latitude = "Latitude is required if Longitude is provided.";
                        } else if (storeDetails.latitude && !constant.LatitudeRegex.test(storeDetails.latitude)) {
                            newErrors.latitude = "Latitude must be between -90 and 90";
                        } else if (storeDetails.latitude && !constant.NumberValidationRegex.test(storeDetails.latitude)) {
                            newErrors.latitude = "Latitude must be a valid number.";
                        }
                    } else if (field === "longitude") {
                        if (storeDetails.latitude && !storeDetails.longitude) {
                            newErrors.longitude = "Longitude is required if Latitude is provided.";
                        } else if (storeDetails.longitude && !constant.LongitudeRegex.test(storeDetails.longitude)) {
                            newErrors.longitude = "Longitude must be between -180 and 180.";
                        } else if (storeDetails.longitude && !constant.NumberValidationRegex.test(storeDetails.longitude)) {
                            newErrors.longitude = "Longitude must be a valid number.";
                        }
                    }

                }
            }
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        } else if (step === 1) {
            for (const field in validationRulesStepTwo) {
                if (validationRulesStepTwo.hasOwnProperty(field)) {
                    if (validationRulesStepTwo[field]?.required && !storeDetails[field]) {
                        newErrors[field] = "This field is required.";
                    } else if ((validationRulesStepTwo[field]?.label?.required) && (!storeDetails[field]?.label)) {
                        newErrors[field] = "Please Select field";
                    } else if (!validationRulesStepTwo[field]?.valid && storeDetails[field]?.length === 0 && imagesURL?.length === 0) {
                        newErrors[field] = "Please upload Images";
                    }
                    // Email validation logic (commented out for now)
                    // if (validationRulesStepTwo[field]?.valid && storeDetails[field]) {
                    //     if (field === "admin_email") {
                    //         let error = validationRulesStepTwo[field]?.pattern.test(storeDetails[field])
                    //         if (!error) {
                    //             newErrors[field] = "Invalid Field";
                    //             setEmailError((prevErrors: any) => ({
                    //                 ...prevErrors, admin_email: "Invalid Email"
                    //             }));
                    //         }
                    //     }
                    // }
                    if (!params.id && !storeDetails.admin_password) {
                        newErrors.admin_password = "This field is required.";
                    }
                }
            }
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        } else if (step === 2) {
            for (const field in validationRulesStepThree) {
                if (validationRulesStepThree.hasOwnProperty(field)) {
                    if (!((storeDetails.gst_type === constant.GST_DETAILS.NA) && (field === 'gst_number' || field === 'gst_document'))) {
                        if (validationRulesStepThree[field]?.required && !storeDetails[field]) {
                            newErrors[field] = "This field is required.";
                        } else if (storeDetails[field]?.length === 0 && imagesURL?.length === 0) {
                            newErrors[field] = "Please Select Document";
                        }
                    }
                }
            }
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        }
    };


    const handleNextStep = () => {
        const isValid = validate();
        if (errors?.store_location && ((updateStoreLocation?.trim() != errors?.store_location?.trim()))) {
            addToast(errors?.store_location, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        } else {
            if (isValid) {
                return true
            }
        }
        return false
    }

    const handleChangePassword = async () => {
        let payload = {
            user_id: storeDetails.store_admin_id,
            password: storeDetails.admin_change_password
        }
        try {
            let response = await service.store.changePassword(payload)
            if (response.status === 200) {
                addToast('Password Change successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                setStoreDetails({ ...storeDetails, admin_change_password: '' })
            }
        } catch (error) {

        }
    }

    const emailValidationMessage = (email: string, name: string) => {
        if (!emailValidation(email)) {
            setEmailError({ ...emailError, [name]: "Invalid Email" });
        } else {
            setEmailError({ ...emailError, [name]: "" });
        }
    };

    return (
        <Wrapper>
            <div className='row align-items-center'>
                <TopBar heading={''} />
            </div>
            {storeDetails && <>
                <Stepper
                    steps={steps}
                    step={step}
                />
                <AddStoreOrganism
                    step={step}
                    setStoreDetails={setStoreDetails}
                    storeDetails={storeDetails}
                    imagesURL={imagesURL}
                    bannerImagesURL={bannerImagesURL}
                    errors={errors}
                    setImageDeleteId={setImageDeleteId}
                    imageDeleteID={imageDeleteID}
                    handleChangePassword={handleChangePassword}
                    handleEmailError={emailValidationMessage}
                    errorMessage={emailError}
                    setErrors={setErrors}
                    updateStoreLocation={updateStoreLocation}
                />
                <BottomNav
                    handleNextStep={handleNextStep}
                    steps={steps}
                    handleSubmit={() => _handleSubmitBtnClick()}
                    setStep={setStep}
                    step={step}
                    handleCancel={() => navigate('/stores')}
                />
            </>}
        </Wrapper>
    )
}

export default AddStore