import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { locationGrouping } = Dictionary

const locationGroupingService = {
  groupList: async () => {
    return axios.get(BASE_URL + locationGrouping.group(),)
  },
  groupMappingList: async () => {
    return axios.get(BASE_URL + locationGrouping.groupMappingList(),)
  },
  remainingPincodesList: async () => {
    return axios.get(BASE_URL + locationGrouping.remainingPincodesList(),)
  },
  getGroupById: async (id: string) => {
    return axios.get(BASE_URL + locationGrouping.getGroupById(id),)
  },
  removeGroup: async (id: string) => {
    return axios.delete(BASE_URL + locationGrouping.getGroupById(id),)
  },
  createGroup: async (payload: any) => {
    return axios.post(BASE_URL + locationGrouping.group(), payload)
  },
  updateGroup: async (id: string, payload: any) => {
    return axios.put(BASE_URL + locationGrouping.updateGroup(id), payload)
  },
  createMapping: async (payload: any) => {
    return axios.post(BASE_URL + locationGrouping.groupMappingList(), payload)
  },

}
export default locationGroupingService
