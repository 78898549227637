import { useState } from 'react'
import atoms from '../../../atoms'
import { FaEdit, FaTrash } from 'react-icons/fa';
import AddNew from "./addNew"
import service from '../../../../api/services';
import { useToasts } from 'react-toast-notifications';
import molecules from '../../../molecules';
const { Button, LocationBadge } = atoms
const { ConfirmationModal } = molecules

const Index = ({ groupList, remainingList, getGroupList, _handleDelete }: any) => {
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [deletePopup, setDeletePopup] = useState<boolean>(false)
    const { addToast } = useToasts();
    const [editData, setEditData] = useState({})
    const [isEditable, setIsEditable] = useState({ id: '', boolean: false })
    const [currSelected, setCurrSelected] = useState<string>('')
    const _handleSubmit = async (payload: {}) => {
        try {
            let response
            if (isEditable?.boolean) {
                response = await service.locationGrouping.updateGroup(isEditable?.id, payload)

            } else {
                response = await service.locationGrouping.createGroup(payload)

            }
            if (response.status === 200 || response.status === 201) {
                getGroupList()
                setModal(false)
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const _handleEdit = async (id: string) => {
        setIsEdit(true)
        setIsEditable({ id: id, boolean: true })
        setModal(true)
        try {
            let response = await service.locationGrouping.getGroupById(id)
            if (response.status === 200) {
                setEditData(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const _handleDeleteConfirm = async () => {
        _handleDelete(currSelected)
        setDeletePopup(!deletePopup)
    }
    return (
        <div className="container-grouping row">
            <div className="col-10">
                <div className='d-flex align-items-baseline'>
                    <h1 className="list-title">Remaining:&nbsp;</h1>
                    <div className="top-items d-flex flex-wrap mb-0">
                        {remainingList && remainingList?.map((item: string, idx: number) => (
                            <LocationBadge key={idx} label={item} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="col-2 text-end">
                <Button name={"Add New"}
                    onClick={() => {       setIsEdit(false) ,setModal(true)}}
                />
            </div>
            <div className="col-12">
                <div className=" py-4">
                    {groupList && groupList?.map((items: any) => (
                        <div className="group-container" key={items.id}>
                            <div className="group">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="group-title">{items?.group_name}</h6>
                                    <div className='d-flex gap-2'>
                                        <button className="btn action-btn" onClick={() => _handleEdit(items.id)}>
                                            <FaEdit />
                                        </button>
                                        <button className="btn action-btn" onClick={() => {
                                            setCurrSelected(items.id)
                                            setDeletePopup(!deletePopup)
                                        }}>
                                            <FaTrash />
                                        </button>
                                    </div>
                                </div>
                                <div className="group-items">
                                    {items?.pincodes?.map((item: string, idx: number) => (
                                        <LocationBadge key={idx} label={item} />
                                    ))}
                                </div>
                            </div>

                        </div>
                    ))
                    }
                </div>
            </div>
            {modal &&
                <AddNew
                    remainingList={remainingList}
                    show={modal}
                    onHide={() => setModal(false)}
                    setPayload={_handleSubmit}
                    editData={editData}
                    isEdit={isEdit}
                />
            }
            {deletePopup && <ConfirmationModal
                show={deletePopup}
                handleConfirmItem={() => _handleDeleteConfirm()}
                onHide={() => setDeletePopup(!deletePopup)}
                message={'delete this group'} />}
        </div>

    )
}

export default Index