import { useEffect, useMemo, useState } from 'react';
import atoms from '../../../atoms'
import service from '../../../../api/services';
import { useToasts } from 'react-toast-notifications';
const { Button } = atoms

const Index = ({ mappingList, transitData }: any) => {
    const groups = mappingList || [];
    const { addToast } = useToasts();

    const combinations = useMemo(() => {
        const getCombinations = (groups: any) => {
            const combinations = [];
            for (let i = 0; i < groups?.length; i++) {
                for (let j = i + 1; j < groups.length; j++) {
                    combinations.push({ group1: groups[i], group2: groups[j], value: '' });
                }
            }
            return combinations;
        };
        return getCombinations(groups);
    }, [groups]);

    const [updatedCombinations, setUpdatedCombinations] = useState(combinations);
    useEffect(() => {
        if (transitData.length > 0) {
            const updated = combinations.map(combo => {
                const matchingData = transitData.find(
                    (item: any) =>
                        (item.group_1.id === combo.group1.id && item.group_2.id === combo.group2.id) ||
                        (item.group_1.id === combo.group2.id && item.group_2.id === combo.group1.id)
                );
                if (matchingData) {
                    return { ...combo, value: matchingData.transit_days.toString() };
                }
                return combo;
            });
            setUpdatedCombinations(updated);
        }
    }, [transitData, combinations]);

    // Handle input change
    const handleInputChange = (index: any, value: any) => {
        const updated = [...updatedCombinations];
        updated[index].value = value;
        setUpdatedCombinations(updated);
    };

    // Handle form submission
    const handleSubmit = async () => {
        const result = updatedCombinations.map(({ group1, group2, value }) => ({
            group_id_1: group1.id,
            group_id_2: group2.id,
            transit_days: Number(value)
        }));
        const payload = {
            mapping_data: result
        };
        try {
            let response = await service.locationGrouping.createMapping(payload);
            if (response.status === 201) {
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }
        } catch (error) {
            console.log(error);
        }
        console.log(result);
    };

    return (
        <div className="page-container">
            <div className="grid-container">
                {updatedCombinations.map((combo, index) => (
                    <div key={index} className="grid-item">
                        <label className="label">
                            {combo.group1.group_name} - {combo.group2.group_name}
                        </label>
                        <input
                            type="text"
                            value={combo.value}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            className="input"
                        />
                    </div>
                ))}
            </div>
            <div className="button-container-bottom">
                <Button onClick={handleSubmit} className="button">Submit</Button>
            </div>
        </div>
    );
};

export default Index;