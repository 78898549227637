import React from 'react';
import './index.scss'
import { BudgesProps } from './interface';
import { FaTimes } from 'react-icons/fa';

const Index = ({ label, onClick, isRemove = false }: BudgesProps) => {
    return (
        <>
            <div className="item d-flex align-items-center justify-content-between">
                <span>{label}</span>
                {isRemove && <button className="btn btn-light btn-sm"
                    onClick={onClick}>
                    <FaTimes />
                </button>}
            </div>
        </>
    );
};

export default Index;
