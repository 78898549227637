const BulkEdit = {
    upload: () => "admin/product/bulk-update",
    downloadExcel: () => "admin/download/product-list",

    // Bulk Approve
    bulkApprove: () => "admin/product/bulk-approve",


}


export default BulkEdit
